import { Button } from '@material-ui/core';
import { Assignment, AssignmentTurnedIn } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { Container } from './style';

interface CopyLinkProps {
  path: string;
}

function CopyLink({ path }: CopyLinkProps): JSX.Element {
  const [wasPathCopied, setWasPathCopied] = useState<boolean>(false);

  const handleOnCopyButtonClick = useCallback(() => {
    setWasPathCopied(true);
    navigator.clipboard.writeText(path);
    toast.success(`O Link foi copiado com sucesso`);
  }, [path]);

  return (
    <Container>
      <Button
        variant="contained"
        onClick={handleOnCopyButtonClick}
        size="large"
      >
        {wasPathCopied ? <AssignmentTurnedIn /> : <Assignment />}
        <p>Copiar Link</p>
      </Button>
    </Container>
  );
}

export default CopyLink;
