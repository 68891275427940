import styled from 'styled-components';

export const Button = styled.button`
  color: #fff;
  background-color: #3f9177;
  padding: 0.8rem 2.5rem;
  border: none;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #246c9f;
    transform: scale(1.05);
  }
`;
