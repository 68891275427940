import React from 'react';
import { RiMoneyDollarBoxLine } from 'react-icons/ri';
import { FaCarAlt, FaHandHoldingUsd, FaHome, FaHourglassEnd, FaRegMoneyBillAlt } from 'react-icons/fa';
import ProductItem from './ProductItem';
import {
  ProductsListContainer,
  Content,
  ProductsBox,
  ProductsImage,
  MoreInfos,
  ContainerInfo,
  InfoText,
  Container,
} from './style';
import Btn from './Button';

function ProductsList(): JSX.Element {
  return (
    <Container>
      <Content>
        <ProductsListContainer>
          <ProductsBox>
            <ProductItem
              name="Crédito Imobiliário"
              icon={<FaHome style={{ fontSize: '30px' }} />}
              backcolor="#E1F9F5"
              color="#6ADFCF"
            />
            <ProductItem
              name="Crédito Veicular"
              icon={<FaCarAlt style={{ fontSize: '30px' }} />}
              backcolor="#DBDBEB"
              color="#4A4C9D"
            />
            <ProductItem
              name="Antecipação de Recebíveis"
              icon={<FaHourglassEnd style={{ fontSize: '30px' }} />}
              backcolor="#D8E7FD"
              color="#3F87F8"
            />
            <ProductItem
              name="Saque FGTS"
              icon={<FaHandHoldingUsd style={{ fontSize: '30px' }} />}
              backcolor="#FBDCDE"
              color="#EB515C"
            />
            <ProductItem
              name="Crédito Consignado"
              icon={<RiMoneyDollarBoxLine style={{ fontSize: '30px' }} />}
              backcolor="#FCEBD9"
              color="#F19C40"
            />
            <ProductItem
              name="Câmbio"
              icon={<FaRegMoneyBillAlt style={{ fontSize: '30px' }} />}
              backcolor="#E0F6E9"
              color="#62D490"
            />
          </ProductsBox>
        </ProductsListContainer>
        <ProductsImage />
      </Content>
      <ContainerInfo>
        <MoreInfos>
          <Btn
            text="Ver mais"
            onClick={() => {
              window.scrollTo({
                top: 950,
                behavior: 'smooth',
              });
            }}
          />
          <InfoText>
            Toque em <a href="#!">Ver mais</a> e encontre <br /> o crédito que
            faz mais sentido para você!
          </InfoText>
        </MoreInfos>
      </ContainerInfo>
    </Container>
  );
}

export default ProductsList;
