import React from 'react';
import ProductsDropDownList from './ProductsDropDownList';
import carIcon from '../../assets/images/car-icon.svg';
import dolarIcon from '../../assets/images/dolar-icon.svg';
import homeIcon from '../../assets/images/home-icon.svg';
import moneyIcon from '../../assets/images/money-icon.svg';
import timeIcon from '../../assets/images/time-icon.svg';
import fgtsIcon from '../../assets/images/fgts-icon.svg';
import { Container } from './style';

function SectionProductsDropDownList(): JSX.Element {
  return (
    <Container>
      <h4 style={{ padding: '50px' }}>
        Temos o <strong style={{ color: '#30766d' }}>crédito ideal</strong>{' '}
        esperando por <strong style={{ color: '#30766d' }}>você</strong> !
      </h4>
      <ProductsDropDownList
        products={[
          {
            name: 'Crédito imobiliário',
            image: homeIcon,
            borderColor: '#6ADFCF',
            description: `Opção de crédito rápido que pode ser utilizado para qualquer finalidade, oferecendo como garantia um imóvel quitado ou não. Também conhecido como empréstimo com garantia de imóvel, possibilita ao proprietário levantar capital de maneira rápida e segura sem precisar se desfazer do imóvel.`,
            subDesription:
              'É uma linha de crédito destinada a pessoa física onde o cliente pode utilizar para qualquer finalidade, tendo como garantia seu imóvel. Um empréstimo com parcelas a longo prazo e juros baixos. Opção certa para levar dinheiro rápido e seguro e para o parceiro Bevi opção para diversificar a carteira.',
            banksList: [
              {
                name: 'Banco Itaú',
                image: 'itau',
              },
              {
                name: 'Santander',
                image: 'santander',
              },
              {
                name: 'Daycoval',
                image: 'daycoval',
              },
              {
                name: 'CrediHome',
                image: 'credihome',
              },
              {
                name: 'Banri',
                image: 'banri',
              },
            ],
            types: 'imobiliario',
            creditTypes: [
              {
                name: 'Financiamento',
                parcelsMax: 420,
                minValue: 120000,
                fees: 0.7,
                average: 0.7,
                creditDescription: {
                  text: 'Crédito para compra, construção ou reforma de imóveis.',
                },
              },
              {
                name: 'Refinanciamento',
                parcelsMax: 240,
                minValue: 50000,
                fees: 0.7,
                average: 0.7,
                creditDescription: {
                  text: 'Crédito com garantia de imóvel para juros mais baixos.',
                },
              },
              {
                name: 'Portabilidade',
                parcelsMax: 420,
                minValue: 50000,
                fees: 0.7,
                average: 0.7,
                creditDescription: {
                  text: 'Transferência de um contrato de crédito de uma instituição financeira para outra instituição financeira.',
                },
              },
            ],
          },
          {
            name: 'Crédito Veicular',
            image: carIcon,
            borderColor: '#4A4C9D',
            description: `Linha de crédito oferecida por instituições financeiras para a compra de veículos.`,
            banksList: [
              { name: 'Banco BV', image: 'bancobv' },
              {
                name: 'Creditas',
                image: 'creditas',
              },
              {
                name: 'Porto Seguro',
                image: 'portoseguro',
              },
            ],
            types: 'veicular',
            creditTypes: [
              {
                name: 'Financiamento',
                parcelsMax: 60,
                minValue: 5000,
                fees: 1.8,
                average: 1.8,
                creditDescription: {
                  text: 'Crédito para aquisição de veículos com as melhores taxas.',
                },
              },
              {
                name: 'Refinanciamento',
                parcelsMax: 60,
                minValue: 5000,
                fees: 1.8,
                average: 1.8,
                creditDescription: {
                  text: 'Crédito com garantia de veículo para juros mais baixos.',
                },
              },
            ],
          },
          {
            name: 'Crédito Consignado',
            image: dolarIcon,
            borderColor: '#F19C40',
            description: `Uma modalidade de juros baixos, produto para funcionários públicos ou pensionistas, onde o desconto das parcelas de pagamento do empréstimo é feito diretamente no contracheque de quem aderiu a essa linha de crédito. O limite é que a parcela comprometa no máximo 30% do salário ou benefício`,
            banksList: [
              { name: 'Fontes', image: 'fontes' },
              { name: 'Prospecta', image: 'prospecta' },
              { name: 'MG Prime', image: 'mgprime' },
            ],
            types: 'consignado',
            creditTypes: [
              {
                name: 'Crédito Consignado',
                parcelsMax: 60,
                minValue: 500,
                fees: 0.8,
                average: 0.8,
                creditDescription: {
                  text: 'Linha de crédito com as melhores taxas.',
                },
              },
            ],
          },
          {
            name: 'Saque FGTS',
            image: fgtsIcon,
            borderColor: '#EB515C',
            description:
              'É uma nova modalidade do crédito pessoal onde é possível colocar como garantia o FGTS do cliente para aumentar o prazo de pagamento e diminuir a taxa de juros.',
            banksList: [
              { name: 'Banco C6', image: 'bancoc6' },
              { name: 'Banco Pan', image: 'bancopan' },
              { name: 'Banco Safra', image: 'bancosafra' },
              { name: 'MG Prime', image: 'mgprime' },
            ],
            types: 'saque-fgts',
            creditTypes: [
              {
                name: 'Crédito com Garantia FGTS',
                parcelsMax: 60,
                minValue: 50,
                fees: 1.49,
                average: 1.49,
                creditDescription: {
                  text: 'Saque seu FGTS com e invista da melhor forma.',
                },
              },
            ],
          },
          {
            name: 'Antecipação de recebíveis',
            image: timeIcon,
            borderColor: '#3F87F8',
            description:
              'Essa operação de crédito possibilita que empresas antecipem os valores de suas vendas a prazo, duplicatas, carnês, entre outras. Dessa forma, esse valor chega ao caixa das empresas antes mesmo da cobrança chegar para o cliente.',
            banksList: [{ name: 'Federal Invest', image: 'federalinvest' }],
            types: 'antecipacao-de-recebiveis',
            creditTypes: [
              {
                name: 'Antecipação de Recebíveis',
                parcelsMax: 60,
                minValue: 500,
                fees: 1.2,
                average: 1.2,
                creditDescription: {
                  text: 'Antecipe os valores de suas vendas a prazo de forma simples.',
                },
              },
            ],
          },
          {
            name: 'Câmbio',
            image: moneyIcon,
            borderColor: '#62D490',
            description:
              'É no mercado de câmbio onde ocorrem as negociações entre moedas de diferentes países, como venda, troca ou compra de valores. Esse produto é para empresas que pretendem fazer negociações de exportação ou importação, facilitando suas operações.',
            banksList: [{ name: 'Banco BS2', image: 'bs2' }],
            types: 'cambio',
            creditTypes: [
              {
                name: 'Câmbio',
                parcelsMax: 60,
                minValue: 100,
                fees: 0.2,
                average: 0.2,
                creditDescription: {
                  text: 'As melhores cotações do mercado.',
                },
              },
            ],
          },
        ]}
      />
    </Container>
  );
}

export default SectionProductsDropDownList;
