/* eslint-disable react/no-array-index-key */
import React from 'react';
import { CardContainer, Title } from './style';
import Card from './Card';
import card1 from '../../../assets/images/Cards/card1.svg';
import card2 from '../../../assets/images/Cards/card2.svg';
import card3 from '../../../assets/images/Cards/card3.svg';

const CardsList = (): JSX.Element => {
  const cardInformations = [
    {
      image: card1,
      title: 'Você solicita uma proposta',
      description:
        'Preenchendo o formulário com seus dados completos e atualizados.',
    },
    {
      image: card2,
      title: 'Nós pesquisamos a oferta ideal de crédito para você',
      description:
        '29 instituições financeiras analisam seu perfil e nós te retornamos a melhor opção e crédito.',
    },
    {
      image: card3,
      title: 'O dinheiro cai na sua conta.',
      description:
        'Você recebe o valor solicitado com as melhores taxas do mercado.',
    },
  ];
  return (
    <>
      <Title>Quer saber mais como funciona o processo?</Title>
      <CardContainer>
        {cardInformations.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </CardContainer>
    </>
  );
};

export default CardsList;
