import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProfileContainer, ProfileBox, ProfileInfo } from './style';
import Avatar from './Avatar';
import { useCompany } from '../../../hooks/Company';

interface ParamsProps {
  id: string;
}

function Profile(): JSX.Element {
  const { id: companyID } = useParams<ParamsProps>();

  const { company, getCompanyInfo } = useCompany();

  useEffect(() => {
    getCompanyInfo(companyID);
  }, [companyID, getCompanyInfo]);

  return (
    <ProfileContainer>
      <ProfileBox>
        <Avatar />
        <ProfileInfo>
          <h1>{company.personName || 'Lcc Hub'}</h1>
          <h2>{company.name || 'LCC'}</h2>
          <h3>Digital Banker</h3>
        </ProfileInfo>
      </ProfileBox>
    </ProfileContainer>
  );
}

export default Profile;
