import styled, { keyframes } from 'styled-components';

const leftToRight = keyframes`
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
`;

export const Container = styled.div`
  background-color: #e9f0f5;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  animation: ${leftToRight} 0.3s ease-in-out;
`;

export const HeaderContainer = styled.div`
  width: 80%;
  max-width: 1440px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  @media screen and (max-width: 900px) {
    .headerBox {
      width: 80%;
    }
  }
`;

export const HeaderBox1 = styled.div`
  display: flex;
  width: 50%;
  align-items: center;

  @media screen and (max-width: 1100px) {
    width: 60%;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const HeaderBox2 = styled.div`
  display: flex;
  width: 40%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 900px) {
    width: 50%;
  }
`;
