import styled from 'styled-components';

export const Icon = styled.span`
  width: 60px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1260px) {
    width: 50px;
    height: 50px;
  }
  @media screen and (max-width: 415px) {
    width: 40px;
    height: 40px;
  }
  @media screen and (max-width: 375px){
    width: 60px;
    height: 60px;
  }
`;
export const Title = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  @media screen and (max-width: 1615px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 1460px) {
    font-size: 1.05rem;
  }
  @media screen and (max-width: 1260px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 1160px) {
    font-size: .9rem;
  }
  @media screen and (max-width: 375px){
    font-size: 1.3rem;
  }
`;

export const ButtonLink = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 100px;
  cursor: pointer;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #333;
  font-size: 1rem;
  &:hover {
    transition: all 0.3s ease-in-out;
    color: #479675 ;
  }
  @media screen and (max-width: 415px) {
    padding: 8px;
  }
  @media screen and (max-width: 375px){
    padding: 15px;
  }
`;

export const ProductItemContainer = styled.div`
  width: 13.125rem;
  height: 13.125rem;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;
  padding: 5px;
  font-size: 0.8rem;

  span {
    font-weight: bold;
    color: #000;
    font-size: 1.3rem;
  }

  span > img {
    width: 65px;
    margin-bottom: -5px;
  }

  span > p {
    font-size: 1.25rem;
  }

  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
  }
  @media screen and (max-width: 1615px) {
    width: 12rem;
    height: 12rem;
  }
  @media screen and (max-width: 1460px) {
    width: 11.3rem;
    height: 11.3rem;
  }
  @media screen and (max-width: 1260px) {
    width: 10.625rem;
    height: 10.625rem;
  }
  @media screen and (max-width: 1160px) {
    width: 10.125rem;
    height: 10.125rem;
  }
  @media screen and (max-width: 1119px) {
    width: 9.625rem;
    height: 9.625rem;
  }
  @media screen and (max-width: 1050px) {
    width: 9.125rem;
    height: 9.125rem;
  }
  @media screen and (max-width: 1024px) {
    width: 11rem;
    height: 11rem;
  }
  @media screen and (max-width: 475px) {
    width: 10rem;
    height: 10rem;
  }
  @media screen and (max-width: 405px) {
    width: 9rem;
    height: 9rem;
  }
  @media screen and (max-width: 375px) {
    min-width: 250px;
    min-height: 250px;
  }
`;
