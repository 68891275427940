import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1rem 0;
  align-self: flex-start;
  padding: 30px 50px;
  @media screen and (max-width: 415px) {
    font-size: 1.3rem;
  }
`;

export const List = styled.div`
  width: 90%;
  display: flex;
  overflow-x: auto;
  margin: 0 auto ;
`;
