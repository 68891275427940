import React from 'react';
import CardsList from './CardsList';
import { Container } from './style';

const SectionCards = (): JSX.Element => {
  return (
    <Container>
      <CardsList />
    </Container>
  );
};

export default SectionCards;
