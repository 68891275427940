import React from 'react';
import { FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import { useCompany } from '../../../hooks/Company';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import {
  ContactContainer,
  ContactBox,
  ContactEmail,
  ContactNumber,
  IconEmail,
  IconNumber,
} from './style';

function Contact(): JSX.Element {
  const { company } = useCompany();
  return (
    <ContactContainer>
      <ContactBox>
        <ContactNumber>
          <IconNumber>
            <FaWhatsapp />
          </IconNumber>
          <p>{formatPhoneNumber(`${company.phone ? company.phone : "99999999999"}`)}</p>
        </ContactNumber>
        <ContactEmail>
          <IconEmail>
            <FaEnvelope />
          </IconEmail>
          <p>{company.email || 'contato@lcchub.com'}</p>
        </ContactEmail>
      </ContactBox>
    </ContactContainer>
  );
}

export default Contact;
