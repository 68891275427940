import './App.css';
import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AvatarProvider } from './hooks/Avatar';
import Routes from './routes';
import client from './services/client';
import 'react-toastify/dist/ReactToastify.css';

import { CompanyProvider } from './hooks/Company';

function App(): JSX.Element {
  return (
    <Router>
      <ToastContainer />
      <ApolloProvider client={client}>
        <CompanyProvider>
          <AvatarProvider>
            <Routes />
          </AvatarProvider>
        </CompanyProvider>
      </ApolloProvider>
    </Router>
  );
}

export default App;
