import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 50px;
  background-color: #e9f0f5;

  p {
    text-align: center;
    font-size: 1.7rem;
    color: #071620;
    padding: 50px 0 50px 0;
    font-weight: bold;
  }
  p > span {
    color: #009c54;
  }
  @media screen and (max-width: 768px) {
    p:last-child {
      font-size: 1.5rem;
      padding: 50px 50px 0 50px;
    }
  }
`;

export const MenuQuestionListDropDown = styled.div`
  width: 90%;
  margin: 0 auto;
`;
