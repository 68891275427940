import styled from 'styled-components';

export const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: #071620;

  @media (max-width: 920px) {
    display: none;
  }
`;
export const ContactBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding-left: 20px;
  border-left: 1px solid #ddd;
`;
export const ContactNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;

  p {
    width: 100%;
  }
`;

export const ContactEmail = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  font-weight: bold;

  p {
    width: 100%;
    font-size: 1rem;
  }
`;

export const IconNumber = styled.span`
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 8px;
  color: #071620;

  svg {
    width: 100%;
    height: 100%;
    fill: #071620;
  }
`;

export const IconEmail = styled.span`
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 8px;
  color: #071620;

  svg {
    width: 100%;
    height: 100%;
    fill: #071620;
  }
`;
