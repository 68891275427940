import React from 'react';
import { Container, Image, BankName, ImageBox } from './style';
import banri from '../../../../../assets/images/Banks/banri.svg';
import credihome from '../../../../../assets/images/Banks/credihome.svg';
import itau from '../../../../../assets/images/Banks/itau.svg';
import santander from '../../../../../assets/images/Banks/santander.svg';
import daycoval from '../../../../../assets/images/Banks/daycoval.svg';
import creditas from '../../../../../assets/images/Banks/creditas.svg';
import portoseguro from '../../../../../assets/images/Banks/portoseguro.svg';
import bancobv from '../../../../../assets/images/Banks/bancobv.svg';
import mgprime from '../../../../../assets/images/Banks/mgprime.svg';
import fontes from '../../../../../assets/images/Banks/fontes.svg';
import prospecta from '../../../../../assets/images/Banks/prospecta.svg';
import bancoc6 from '../../../../../assets/images/Banks/bancoc6.svg';
import bancosafra from '../../../../../assets/images/Banks/bancosafra.svg';
import bancopan from '../../../../../assets/images/Banks/bancopan.svg';
import bs2 from '../../../../../assets/images/Banks/bs2.svg';
import federalinvest from '../../../../../assets/images/Banks/federalinvest.svg';

interface IBankName {
  name: string;
  image: string;
}

const Bank = ({ name, image }: IBankName): JSX.Element => {
  const currentImage = () => {
    switch (image) {
      case 'creditas':
        return creditas;
      case 'portoseguro':
        return portoseguro;
      case 'bancobv':
        return bancobv;
      case 'banri':
        return banri;
      case 'credihome':
        return credihome;
      case 'itau':
        return itau;
      case 'santander':
        return santander;
      case 'daycoval':
        return daycoval;
      case 'mgprime':
        return mgprime;
      case 'fontes':
        return fontes;
      case 'prospecta':
        return prospecta;
      case 'bancoc6':
        return bancoc6;
      case 'bancosafra':
        return bancosafra;
      case 'bancopan':
        return bancopan;
      case 'bs2':
        return bs2;
      case 'federalinvest':
        return federalinvest;
      default:
        return santander;
    }
  };
  return (
    <>
      <Container>
        <ImageBox>
          <Image
            style={{
              backgroundImage: `url(${currentImage()})`,
            }}
          />
          <BankName>{name}</BankName>
        </ImageBox>
      </Container>
    </>
  );
};

export default Bank;
