import { ApolloError, useLazyQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
} from 'react';
import { toast } from 'react-toastify';

import {
  ICompany,
  getCompanyInfoQuery,
  IGetCompanyInfoQueryResponse,
  IGetCompanyInfoQueryVars,
} from '../GraphQL/modules/Company/queries/getCompany';

interface ICompanyContext {
  company: ICompany;
  setCompany(company: ICompany): void;
  getCompanyInfo(id: string): void;
  loading: boolean;
}

const companyContext = createContext<ICompanyContext>({} as ICompanyContext);

interface ICompanyResolver {
  children: ReactNode;
}

export function CompanyProvider({ children }: ICompanyResolver): JSX.Element {
  const [company, setCompany] = useState<ICompany>({} as ICompany);

  const handleOnGetCompanyQueryCompleted = useCallback(
    ({ getCompanyInfo: fetchedCompany }: IGetCompanyInfoQueryResponse) => {
      if (fetchedCompany.isSuspended === true) {
        toast.error('Empresa suspensa! Entre em contato com o suporte.');
        return;
      }
      setCompany(fetchedCompany);
      toast.success(`Seja bem vindo(a)!`);
    },
    [],
  );
  const handleOnGetCompanyQueryError = useCallback(
    ({ message }: ApolloError) => {
      return message;
    },
    [],
  );

  const [getCompanyInfo, { loading }] = useLazyQuery<
    IGetCompanyInfoQueryResponse,
    IGetCompanyInfoQueryVars
  >(getCompanyInfoQuery, {
    onCompleted: handleOnGetCompanyQueryCompleted,
    onError: handleOnGetCompanyQueryError,
  });

  const queryCompanyInfo = useCallback(
    (id: string) => {
      getCompanyInfo({
        variables: {
          id,
        },
      });
    },
    [getCompanyInfo],
  );

  return (
    <companyContext.Provider
      value={{ company, getCompanyInfo: queryCompanyInfo, loading, setCompany }}
    >
      {children}
    </companyContext.Provider>
  );
}

export function useCompany(): ICompanyContext {
  const context = useContext(companyContext);
  if (!context) {
    throw new Error('useCompany must be used within a CompanyProvider');
  }
  return context;
}
