import styled, { keyframes } from 'styled-components';
import img1 from '../../../assets/images/img1.png';
import img2 from '../../../assets/images/img2.png';
import logoMobile from '../../../assets/images/logolcc-mobile.png';

const leftToRight = keyframes`
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;
const rightToLeft = keyframes`
  from {
    transform: translateX(+100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  width: 80%;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 25px;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const ProductsListContainer = styled.div`
  width: 50%;
  max-width: 800px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  animation: ${leftToRight} 0.8s ease-in-out;
  @media screen and (max-width: 1755px) {
    width: 60%;
  }
  @media screen and (max-width: 1560px) {
    width: 70%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const ProductsBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  @media screen and (max-width: 1024px) {
    justify-content: center;
    margin: 25px 0px 0px 0px;
  }
`;

export const ProductsImage = styled.div`
  width: 50%;
  max-width: 800px;
  height: 100%;
  display: flex;
  min-height: 460px;
  max-height: 470px;
  display: flex;
  border-radius: 0.5rem;
  background-size: cover;
  /*   background-repeat: no-repeat; */
  background-position: center;
  background-image: url(${img1});
  animation: ${rightToLeft} 0.8s ease-in-out;

  img {
    width: 100%;
    height: 100%;
    min-height: 460px;
    max-height: 470px;
  }

  @media screen and (max-width: 1755px) {
    background-size: contain;
    background-repeat: no-repeat;
  }
  @media screen and (max-width: 1570px) {
    min-height: 420px;
  }
  @media screen and (max-width: 1350px) {
    width: 40%;
    min-height: 400px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${img2});
  }
  @media screen and (max-width: 1200px) {
    min-height: 380px;
  }
  @media screen and (max-width: 1100px) {
    min-height: 360px;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    display: flex;
    min-height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${logoMobile});
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const ContainerInfo = styled.div`
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
`;

export const MoreInfos = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
`;

export const InfoText = styled.span`
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.7);

  a {
    color: rgba(255, 255, 255, 0.7);
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
  }
`;
