import styled from 'styled-components';
import colors from '../../../../Styles/colors';

export const Container = styled.section`
  width: 100%;
  height: min-content;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > button {
    background-color: ${colors.primary.normal.first};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    padding: 12px;
    height: 100%;
    color: ${colors.text.first};
    &:hover {
      background-color: ${colors.primary.normal.second};
    }
    @media (max-width: 768px) {
      width: 50px;
      p {
        display: none;
      }
    }
    @media (max-width: 375px) {
      width: 40px;
    }
  }
`;
