import { gql } from '@apollo/client';

export interface IGetCompanyInfoQueryVars {
  id: string;
}

export interface ICompany {
  id: string;
  name: string;
  personName: string;
  email: string;
  phone: string;
  isSuspended: boolean;
  avatarBitrixFileID: number;
}

export interface IGetCompanyInfoQueryResponse {
  getCompanyInfo: ICompany;
}

const getCompanyInfoQuery = gql`
  query getCompanyInfo($id: String!) {
    getCompanyInfo(data: { id: $id }) {
      id
      name
      personName
      email
      phone
      isSuspended
      avatarBitrixFileID
    }
  }
`;

export { getCompanyInfoQuery };
