import React from 'react';
import { FaHeart } from 'react-icons/fa';
import { FooterContainer } from './style';

const Footer = (): JSX.Element => {
  return (
    <FooterContainer>
      <p>
        Powered by - <strong>LCC.hub</strong> © 2022 - Feito com{' '}
        <FaHeart style={{ color: 'red' }} /> em Natal/RN
      </p>
    </FooterContainer>
  );
};

export default Footer;
