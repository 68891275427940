import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ImageBox = styled.div`
  min-width: 200px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const BankName = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: #121214;
  @media screen and (max-width: 415px) {
    font-size: 1rem;
  }
`;

export const Image = styled.div`
  width: 60px;
  height: 60px;
  min-height: 60px;
  min-width: 60px;
  border-radius: 50%;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 415px) {
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
  }
`;
