import React, { Fragment, useEffect, useState } from 'react';
import { useCompany } from '../../../hooks/Company';
import BanksList from './BanksList';
import ProductTable from './ProductTable';
import {
  Container,
  DropListBox,
  Products,
  DescriptionShow,
  Link,
  Card,
  CardDescription,
  Title,
} from './style';

interface IProductsData {
  products: {
    name: string;
    description?: string;
    subDesription?: string;
    image?: string;
    types?: string;
    borderColor: string;
    banksList: {
      name: string;
      image: string;
    }[];
    creditTypes: {
      name: string;
      parcelsMax?: number;
      minValue?: number;
      fees?: number;
      average?: number;
      creditDescription: {
        text: string;
      };
    }[];
  }[];
}

function ProductsDropDownList({ products }: IProductsData): JSX.Element {
  const [selected, setSelected] = useState<number>();
  const { company } = useCompany();

  const handleClick = (index: number): void => {
    if (selected === index) {
      setSelected(undefined);
    } else {
      setSelected(index);
    }
  };

  useEffect(() => {
    setSelected(0);
  }, []);

  return (
    <Container>
      <DropListBox>
        {products.map((product, index) => {
          const newIndex = index;
          return (
            <Card
              key={newIndex}
              onClick={() => handleClick(index)}
              role="button"
            >
              <Products
                style={{
                  border: `${selected === index ? `1px solid ${product.borderColor}` : ''}`,
                  boxShadow: `${
                    selected === index ? '5px 5px 10px 0px rgba(0,0,0,0.2)' : ''
                  }`,
                }}
              >
                <img src={product.image} alt={product.name} />
                <span>{product.name}</span>
              </Products>
            </Card>
          );
        })}
      </DropListBox>
      <CardDescription>
        {products.map((product, index) => {
          const newIndex = index;
          const creditTypes = product.creditTypes.map((creditType) => {
            return {
              name: creditType.name,
              parcelsMax: creditType.parcelsMax,
              minValue: creditType.minValue,
              fees: creditType.fees,
              average: creditType.average,
              creditDescription: creditType.creditDescription,
            };
          });
          const banksList = product.banksList.map((bank) => {
            return {
              name: bank.name,
              image: bank.image,
            };
          });
          return (
            <Fragment key={newIndex}>
              {selected === index ? (
                <DescriptionShow>
                  <Title>{product.name}</Title>
                  <h3>{product.description}</h3>
                  <h3
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    {product.subDesription}
                  </h3>
                  <BanksList banks={banksList} />
                  <ProductTable creditTypes={creditTypes} />
                  <Link
                    href={`https://querocredito.app.br/${product.types}/${company.id}`}
                    target="_blank"
                  >
                    SOLICITAR PROPOSTA
                  </Link>
                </DescriptionShow>
              ) : null}
            </Fragment>
          );
        })}
      </CardDescription>
    </Container>
  );
}

export default ProductsDropDownList;
