import React, { useState } from 'react';
import arrowDownIcon from '../../../assets/images/arrow-down-icon.svg';
import {
  ListContainer,
  QuestionListBox,
  Questions,
  Answer,
  Card,
} from './style';

function QuestionsDropDownList(): JSX.Element {
  const [selected, setSelected] = useState<number>();

  const questions = [
    {
      text: 'Como é feita a analise de crédito?',
      answer:
        'O processo de analise de crédto ocorre quando você faz algum pedido de emprestimo, financiamento, aumento de limite de cartão ou até cartão novo. Os bancos e instituições financeiras fazem a analise para garantir que você realmente terá condições para pagar o crédito pedido. O que depende muito da sua renda e o seu relacionamento com o  mercado. A partir da avaliação, poderão  ser variados os seguintes valores: - Valor liberado; Taxa de juros; e a quantidade de parcelas. O principal objetivo da avaliação é garantir ao banco que vai receber o dinheiro liberado. Outro ponto é saber o número de dívidas que você tem com o banco, para assim saber se você também não corre o risco de comprometer toda sua renda.',
    },
    {
      text: 'Como é feita a solicitação de crédito?',
      answer:
        'Ao você examinar a sua demanda, basta localizar o crédito que mais irá te atender e em seguidar preencher o formulário de solicitação de crédito que está localizado à cima.',
    },
    {
      text: 'Ao receber a simulação solicitado, já possuou o crédito aprovado?',
      answer:
        'Ao receber a simulação do seu crédito, não significa que terás o crédito aprovado. A simulação inicialmente serve para ter uma previsão das parcelas que serão pagas.',
    },
  ];

  const handleClick = (index: number): void => {
    if(selected === index) {
      setSelected(undefined);
    } else {
      setSelected(index);
    }
  };

  return (
    <ListContainer>
      <QuestionListBox>
        {questions.map((question, index) => {
          const newIndex = index + 1;
          return (
            <Card
              key={newIndex}
              onClick={() => handleClick(index)}
              role="button"
            >
              <Questions>
                <span>{question.text}</span>
                <img
                  style={{
                    marginRight: '10px',
                    transform: `rotate(${
                      selected === index ? '180deg' : '0deg'
                    })`,
                  }}
                  src={arrowDownIcon}
                  alt="arrow"
                />
              </Questions>
              {selected === index ? (
                <Answer>
                  <h3>{question.answer}</h3>
                </Answer>
              ) : (
                <Answer style={{ display: 'none' }}>
                  <h3>{question.answer}</h3>
                </Answer>
              )}
            </Card>
          );
        })}
      </QuestionListBox>
    </ListContainer>
  );
}

export default QuestionsDropDownList;
