import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const PartnersListContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 1.5rem 0;
  overflow: hidden;
  scroll-behavior: smooth;

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
  }
`;
