import React from 'react';
import PartnersList from './PartnersList';
// import logolcc from '../../assets/images/logolcc.png';
import safraIcon from '../../assets/images/partners/safra-icon.svg';
import portoseguro from '../../assets/images/partners/portoseguro.svg';
import santander from '../../assets/images/partners/santander.svg';
import cashme from '../../assets/images/partners/cashme.svg';
import daycoval from '../../assets/images/partners/daycoval.svg';
import bcredi from '../../assets/images/partners/bcredi.svg';
import banri from '../../assets/images/partners/banri.svg';
import bs2 from '../../assets/images/partners/bs2.svg';
import inter from '../../assets/images/partners/inter.svg';
import creditas from '../../assets/images/partners/creditas.svg';
import credihome from '../../assets/images/partners/credihome.svg';
import { Container, PartnerInfo } from './style';

function SectionPartners(): JSX.Element {
  return (
    <Container>
      <PartnerInfo style={{ padding: '50px 0 0 0' }}>
        <p>Hub de soluções financeiras conectadas a mais de 28 parceiros</p>
      </PartnerInfo>
      <PartnersList
        partners={[
          {
            name: 'Banco Safra',
            image: `${safraIcon}`,
          },
          {
            name: 'Porto Seguro',
            image: `${portoseguro}`,
          },
          {
            name: 'Santander',
            image: `${santander}`,
          },
          {
            name: 'Cashme',
            image: `${cashme}`,
          },
          {
            name: 'Daycoval',
            image: `${daycoval}`,
          },
          {
            name: 'CrediHome',
            image: `${credihome}`,
          },
          {
            name: 'Banco Banri',
            image: `${banri}`,
          },
          {
            name: 'B Credi',
            image: `${bcredi}`,
          },
          {
            name: 'Banco BS2',
            image: `${bs2}`,
          },
          {
            name: 'Banco Inter',
            image: `${inter}`,
          },
          {
            name: 'Banco Creditas',
            image: `${creditas}`,
          },
        ]}
      />
    </Container>
  );
};

export default SectionPartners;
