import React from 'react';

import {
  Avatar as AvatarComponent,
  AvatarProps,
  CircularProgress,
} from '@material-ui/core';
import { useAvatar } from '../../../../hooks/Avatar';

function Avatar({ ...rest }: AvatarProps): JSX.Element {
  const {
    avatarSource,
    isAvatarUploading,
  } = useAvatar();

  return isAvatarUploading ? (
    <CircularProgress />
  ) : (
    <AvatarComponent
      src={avatarSource || ''}
      style={{
        width: '70px',
        height: '70px',
      }}
      {...rest}
    />
  );
}

export default Avatar;
