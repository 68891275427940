import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import api from '../services/api';
import { useCompany } from './Company';

interface AvatarContextData {
  avatarSource: string | null;
  getAvatarFromApi(): Promise<void>;
  isAvatarUploading: boolean;
  setIsAvatarUploading(isAvatarUploading: boolean): void;
}

const AvatarContext = createContext<AvatarContextData>({} as AvatarContextData);

interface AvatarProviderProps {
  children: ReactNode;
}

export function AvatarProvider({ children }: AvatarProviderProps): JSX.Element {
  const [avatarSource, setAvatarSource] = useState<string | null>(null);
  const [isAvatarUploading, setIsAvatarUploading] = useState<boolean>(false);
  const { company } = useCompany();

  const getAvatarFromApi = useCallback(async (): Promise<void> => {
    if (company.id) {
      try{
        const response = await api.get(`/company/avatar/${company.id}`, {
          responseType: 'arraybuffer',
        });
        const avatarFile = new Blob([response.data]);
        const avatarURL = URL.createObjectURL(avatarFile);
        setAvatarSource(avatarURL);
      } catch (error) {
        setAvatarSource(null);
      }
    }
  }, [company]);

  useEffect(() => {
    if (company.id) {
      getAvatarFromApi();
    } else {
      setAvatarSource(null);
    }
  }, [getAvatarFromApi, company]);

  return (
    <AvatarContext.Provider
      value={{
        avatarSource,
        getAvatarFromApi,
        isAvatarUploading,
        setIsAvatarUploading,
      }}
    >
      {children}
    </AvatarContext.Provider>
  );
}

export function useAvatar(): AvatarContextData {
  const context = useContext(AvatarContext);
  if (!context) {
    throw new Error('useAvatar must be used within a AvatarProvider');
  }
  return context;
}
