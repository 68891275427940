import styled from 'styled-components';

export const FooterContainer = styled.div`
  background-color: #071620;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  letter-spacing: 1px;
  font-size: 1.2rem;
  font-weight: lighter;
  padding: 40px;
  text-align: center;
`;
