import React from 'react';
import { useCompany } from '../../../hooks/Company';
import CopyLink from './CopyLink';

function ButtonShare(): JSX.Element {
  const { company } = useCompany();
  return (
    <CopyLink
      key={company.id}
      path={`https://loja.lcchub.com.br/digitalbanker/${company.id}`}
    />
  );
}

export default ButtonShare;
