import styled from 'styled-components';

export const ProfileContainer = styled.div`
  display: flex;
  width: 50%;
  color: #071620;
`;

export const ProfileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 375px) {
    justify-content: center;
  }
`;

export const ProfileInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 120px;

  h1 {
    width: 100%;
    font-size: 1.2rem;
    font-weight: bold;
  }
  h2 {
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    color: #3f9177;
    font-weight: bold;
    padding: 5px 0;
  }
  h3 {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: 415px) {
    h1 {
      font-size: 1.2rem;
    }
  }
`;
