import React from 'react';
import { ProductItemContainer, Icon, Title, ButtonLink } from './style';

interface IProductItem {
  icon: JSX.Element;
  name: string;
  backcolor: string;
  color: string;
}

function ProductItem({
  icon,
  name,
  backcolor,
  color,
}: IProductItem): JSX.Element {
  const handleClick = () => {
    window.scrollTo({
      top: 1000,
      behavior: 'smooth',
    });
  };

  return (
    <ProductItemContainer>
      <Icon style={{ backgroundColor: `${backcolor}`, color: `${color}` }}>
        {icon}
      </Icon>
      <Title>{name}</Title>
      <ButtonLink onClick={handleClick}>Saiba mais</ButtonLink>
    </ProductItemContainer>
  );
}

export default ProductItem;
