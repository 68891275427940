import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 150px;
  background: #e9f0f5;

  h4 {
    text-align: center;
    font-size: 1.9rem;
    font-weight: bold;
    color: #071620;
  }

  @media screen and (max-width: 415px) {
    h4 {
      font-size: 1.5rem;
    }
  }
`;
