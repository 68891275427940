import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  padding: 50px 0 70px 0;
  @media screen and (max-width: 768px) {
    overflow-x: auto;
    padding: 30px 0 50px 0;
  }
`;

export const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #444;
  padding: 0 0 0 50px;
  @media screen and (max-width: 415px) {
    padding: 0 0 0 20px;
  }
`;

export const ColumnOne = styled.div`
  width: 50%;
  display: flex;
  align-items: center;

  padding: 20px 0;
  font-size: 1rem;
  font-weight: 500;
  @media screen and (max-width: 415px) {
    font-size: 0.9rem;
  }
`;

export const ColumnTwo = styled.div`
  width: Calc(50% / 3);
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  font-size: 1rem;
  font-weight: 500;

  @media screen and (max-width: 415px) {
    font-size: 0.9rem;
  }
`;

export const RowBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }
`;

export const CellOne = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  padding: 20px 0px 20px 50px;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2;

  p {
    font-size: 0.875rem;
    margin-top: 3px;
  }

  @media screen and (max-width: 415px) {
    width: 30% !important;
    padding: 10px 10px 0px 20px;
    font-size: 0.875rem;

    p {
      font-size: 0.6rem;
    }
  }
`;
export const RowTwo = styled.div`
  width: 60%;
  display: flex;
  gap: 10px;
  justify-content: stretch;
  align-items: center;
  @media screen and (max-width: 415px) {
    justify-content: center;
  }
`;

export const CellTwo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  font-size: 1rem;
  @media screen and (max-width: 768px) {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 415px) {
    justify-content: flex-end;
    font-size: 0.7rem;
    padding: 0;
  }
`;
