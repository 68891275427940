import React from 'react';
import Bank from './Bank';
import { Title, Container, List } from './style';

interface IBanks {
  banks: {
    name: string;
    image: string;
  }[];
}

const BanksList = ({ banks }: IBanks): JSX.Element => {
  return (
    <Container>
      <Title>Instituições Parceiras</Title>
      <List>
        {banks.map((bank, index) => (
          <Bank
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            name={bank.name}
            image={bank.image}
          />
        ))}
      </List>
    </Container>
  );
};

export default BanksList;
