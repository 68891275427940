import React from 'react';
import QuestionsDropDownList from './QuestionsDropDownList';
import { Container, MenuQuestionListDropDown } from './style';

function SectionCommonQuestions(): JSX.Element {
  return (
    <Container>
      <p>Perguntas frequentes</p>
      <MenuQuestionListDropDown>
        <QuestionsDropDownList />
      </MenuQuestionListDropDown>
      <p style={{ paddingTop: '70px' }}>
        Ainda resta dúvidas? <span>Fale com nosso Suporte!</span>
      </p>
    </Container>
  );
};

export default SectionCommonQuestions;
