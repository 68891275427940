import styled, { keyframes } from 'styled-components';

const leftToRight = keyframes`
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1rem 0;
  align-self: flex-start;
  padding: 30px 50px;
  @media screen and (max-width: 415px) {
    font-size: 1.3rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DropListBox = styled.div`
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  padding: 10px;
  @media screen and (max-width: 1600px) {
    overflow-x: auto;
  }
`;

export const Card = styled.div`
  width: 100%;
  height: 100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const CardDescription = styled.div`
  display: flex;
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
  gap: 20px;
  flex-wrap: wrap;
`;

export const Products = styled.div`
  width: 200px;
  max-width: 210px;
  display: flex;
  align-items: center;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  transition: all 0.3s;
  cursor: pointer;
  background-color: #fff;
  font-size: 20px;
  gap: 20px;
  span {
    font-size: 1.2rem;
  }
`;

export const DescriptionShow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 0;
  align-items: center;
  margin: 0 auto;
  margin-top: 5px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  animation: ${leftToRight} 0.5s ease-in-out;

  h3 {
    font-size: 1.2rem;
    opacity: 0.8;
    font-weight: 400;
    color: #444;
    padding: 0 50px;
  }
  @media screen and (max-width: 768px) {
    h3 {
      font-size: 0.875rem;
    }
  }
`;

export const DropDownListInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;

  p {
    padding: 10px 0px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 0px;
    gap: 0px;
  }
`;

export const Item = styled.span`
  display: flex;
  padding: 0px 20px;
  align-items: center;
  gap: 10px;
  font-size: 1.3rem;
  font-weight: bold;
  & > p {
    color: #3f9177;
    font-size: 1.3rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
    gap: 5px;
    p {
      font-size: 1.1rem;
    }
  }
  @media screen and (max-width: 450px) {
    p {
      font-size: 1rem;
    }
  }
`;

export const Link = styled.a`
  width: 40%;
  min-width: 200px;
  padding: 15px 25px;
  border-radius: 5px;
  text-align: center;
  border: none;
  background-color: #3f9177;
  color: #fff;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1.2rem;
  transition: all 0.3s ease-in-out;
  margin-top: -65px;
  margin-bottom: 80px ;
  letter-spacing: .1rem;
  text-decoration: none;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: #4aac8c;
    color: #fff;
  }
  @media screen and (max-width: 415px) {
    min-width: 150px;
    margin-top: -55px;
    padding: 10px;
    margin-bottom: 30px;
    font-size: .6rem;
  }
`;
