import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #071620;
  text-align: center;
`;

export const PartnerInfo = styled.span`
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
  p {
    color: #ddd;
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }
  @media screen and (max-width: 768px) {
  p {
      font-size: 1.3rem;
      padding: 0 50px;
    }
  }
`;
