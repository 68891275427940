import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  width: 30%;
  height: 100%;
  min-height: 500px;
  max-width: 450px;
  min-width: 320px;
  margin: 0 auto;
  flex-wrap: nowrap;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
  @media screen and (max-width: 415px) {
    transform: scale(1.0);
    min-height: 400px;
  }
`;

export const CardImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  @media screen and (max-width: 415px){
    margin-top: 40px;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  max-width: 130px ;
  min-height: 130px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 415px){
    max-width: 110px;
    min-height: 110px;
  }
`;

export const CardTitle = styled.h1`
  font-size: 1.6rem;
  font-weight: bold;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;
  @media screen and (max-width: 1200px){
    font-size: 1.5rem;
  }
  @media screen and (max-width: 900px){
    font-size: 1.4rem;
  }
  @media screen and (max-width: 415px){
    font-size: 1.2rem;
    margin-top: 30px;
  }
`;

export const CardDescription = styled.p`
  font-size: 1.2rem;
  color: #555;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
  @media screen and (max-width: 900px){
    font-size: 1.1rem;
  }
  @media screen and (max-width: 415px){
    font-size: 1.1rem;
    margin-top: 20px;
  }
`;
