import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DigitalBanker from '../pages/DigitalBanker';

function Routes(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/digitalbanker/:id" component={DigitalBanker}/>
    </Switch>
  );
}

export default Routes;
