import styled from 'styled-components';

export const ListContainer = styled.div`
  width: 100%;
  display: flex;
`;
export const QuestionListBox = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Card = styled.div``;

export const Questions = styled.div`
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  font-size: 1.3rem;

  span {
    padding: 20px;
  }
`;
export const Answer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: -20px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  h3 {
    padding: 25px;
    font-size: 1.3rem;
    opacity: 0.8;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
`;
