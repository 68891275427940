import React, { useCallback, useEffect, useState } from 'react';
import { Container, PartnersListContainer } from './style';

interface IPartner {
  partners: {
    name: string;
    image: string;
  }[];
}

function PartnersList({ partners }: IPartner): JSX.Element {
  const [state, setState] = useState({
    activeIndex: 0,
    translate: 0,
    transition: 0.6,
  });

  const nextSlide = useCallback(() => {
    if (state.activeIndex === partners.length - 1) return;
    setState((prevState) => ({
      ...prevState,
      activeIndex: prevState.activeIndex + 1,
      translate: (prevState.activeIndex + 1) * -100,
    }));
  }, [state.activeIndex, partners.length]);

  useEffect(() => {
    if (state.activeIndex === partners.length - 5) {
      setState((prevState) => ({
        ...prevState,
        activeIndex: 0,
        translate: 0,
      }));
    }
  }, [state.activeIndex, partners.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 4000);
    return () => clearInterval(interval);
  }, [nextSlide]);

  return (
    <Container>
      <PartnersListContainer>
        {partners.map((partner, index) => {
          const newIndex = index + 1;
          return (
            <div
              style={{
                transform: `translateX(${state.translate}%)`,
                transition: `all ${state.transition}s ease-in-out`,
              }}
              className="partner"
              key={newIndex}
            >
              <img src={partner.image} alt={partner.name} />
            </div>
          );
        })}
      </PartnersListContainer>
    </Container>
  );
}

export default PartnersList;
