import React from 'react';
import ButtonShare from './ButtonShare';
import Contact from './Contact';
import Profile from './Profile';
import { Container, HeaderContainer, HeaderBox1, HeaderBox2 } from './style';

function Header(): JSX.Element {
  return (
    <Container>
      <HeaderContainer>
        <HeaderBox1>
          <Profile />
          <Contact />
        </HeaderBox1>
        <HeaderBox2>
          <ButtonShare />
        </HeaderBox2>
      </HeaderContainer>
    </Container>
  );
}

export default Header;
