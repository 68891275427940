import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 100px;
  padding: 20px 0px;
  @media screen and (max-width: 740px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    flex-wrap: nowrap;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  padding: 2rem 2.5rem;
  color: #fff;
  border-radius: 10px;
  margin: 50px 0;
  @media screen and (max-width: 415px){
    margin: 10px 0 0 0;
    font-size: 1.5rem;
  }
`;
