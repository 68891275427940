import React from 'react';
import {
  CardContainer,
  CardImage,
  Image,
  CardTitle,
  CardDescription,
} from './style';

interface ICard {
  image: string;
  title: string;
  description: string;
}

const Card = ({ image, title, description }: ICard): JSX.Element => {
  return (
    <CardContainer>
      <CardImage>
        <Image
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      </CardImage>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardContainer>
  );
};

export default Card;
