/* eslint-disable react/no-array-index-key */
import { TableBody } from '@material-ui/core';
import React from 'react';
import formatToPTBRMoneyValue from '../../../../utils/formatMoney';
import {
  Container,
  Head,
  Row,
  ColumnOne,
  ColumnTwo,
  CellOne,
  CellTwo,
  RowBody,
  RowTwo,
} from './style';

interface IProducts {
  creditTypes: {
    name: string;
    parcelsMax?: number;
    minValue?: number;
    fees?: number;
    average?: number;
    creditDescription: {
      text: string;
    };
  }[];
}

const ProductTable = ({ creditTypes }: IProducts): JSX.Element => {
  return (
    <Container
      style={{
        backgroundColor: '#F8F8F8',
      }}
    >
      <Head>
        <Row>
          <ColumnOne>Tipos de Crédito</ColumnOne>
          <>
            <ColumnTwo>Prazos</ColumnTwo>
            <ColumnTwo>Taxas</ColumnTwo>
            <ColumnTwo>Valor mínimo</ColumnTwo>
          </>
        </Row>
      </Head>
      <TableBody>
        {creditTypes.map((creditType, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <RowBody key={index}>
            <CellOne
              style={{
                width: '50%',
              }}
            >
              <strong>{creditType.name}</strong>
              <p
                style={{
                  fontWeight: 'normal',
                }}
              >
                {creditType.creditDescription.text}
              </p>
            </CellOne>
            <RowTwo>
              <CellTwo>até {creditType.parcelsMax} meses</CellTwo>
              <CellTwo>
                <strong>
                  a partir de {Number(creditType.fees).toFixed(2)}% a.m.
                </strong>
              </CellTwo>
              <CellTwo style={{
                paddingRight: '20px',
              }}>
                {formatToPTBRMoneyValue(Number(creditType.minValue))}
              </CellTwo>
            </RowTwo>
          </RowBody>
        ))}
      </TableBody>
    </Container>
  );
};

export default ProductTable;
