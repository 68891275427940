import React, { MouseEventHandler } from 'react';
import { Button } from './style';

interface IButtonProps {
  text: string;
  onClick?: MouseEventHandler;
}

function Btn({ text, onClick }: IButtonProps): JSX.Element{
  return <Button onClick={onClick}>{text}</Button>;
}

export default Btn;
