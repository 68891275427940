import React, { useEffect, useState } from 'react';
/* import { useParams } from 'react-router-dom';
import { useCompany } from '../../hooks/Company'; */
import Header from '../../components/Header/Header';
import SectionProducts from '../../components/SectionProducts';
import SectionPartners from '../../components/SectionPartners';
import SectionProductsDropDown from '../../components/SectionProductsDropDown';
import SectionCommonQuestions from '../../components/SectionCommonQuestions';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';
import { ContainerMain } from './style';
import SectionCards from '../../components/SectionCards';

const DigitalBanker = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [setIsLoading]);

  return (
    <ContainerMain>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          <Header />
          <SectionProducts />
          <SectionPartners />
          <SectionProductsDropDown />
          <SectionCards />
          <SectionCommonQuestions />
          <Footer />
        </>
      )}
    </ContainerMain>
  );
};

export default DigitalBanker;
