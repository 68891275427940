import React from 'react';
import Products from './Products';
import { SectionProductsContainer } from './style';

function SectionProducts(): JSX.Element {
  return (
    <SectionProductsContainer>
      <Products />
    </SectionProductsContainer>
  );
};


export default SectionProducts;
